/* tslint:disable:max-line-length */
import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ApiHelper} from './../../_helpers/api.helper';
import {CommonHelper} from './../../_helpers/common.helper';
import {ConstantHelper} from './../../_helpers/constant.helper';
import {AppConfig} from './../../../app/app.config';
import {UserService} from './../../_services/user.service';
import {SweetAlertHelper} from 'src/app/_helpers/sweet.alert.helper';
import {Message} from 'src/app/_locale/message';

@Component({
  selector: 'app-admin-transfer-log',
  templateUrl: './admin-transfer-log.component.html',
  styleUrls: ['./admin-transfer-log.component.scss'],
})
export class AdminTransferLogComponent implements OnInit {
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  httpOptions = {
    headers: this.httpHeaders,
  };
  childRoute: string;
  loggedInUserId: string;
  comapnyName: string = ConstantHelper.COMPANY_NAME;
  companySlug: string = ConstantHelper.COMPANY_SLUG;
  showDataVal: string;
  logOne: any = [];
  logTwo: any = [];
  logThree: any = [];
  logFour: any = [];
  logFive: any = [];
  selectedPatient: any = {};
  selectedRequesting: any = {};
  showPatientModal = false;
  showEmailModal = false;
  showRequestingModal = false;
  loggedInUserRoleId = localStorage.getItem('loggedInUserRoleId');
  message = Message;
  OpinionLogRequesteRespondedArray: any = [];
  OpinionLogRequesteNotRespondedArray: any = [];
  OpinionLogRequesteReadArray: any = [];
  expertPhysicianOpinionRespondedArray: any = [];
  expertPhysicianOpinionNotRespondedArray: any = [];
  expertPhysicianOpinionReadArray: any = [];
  doctorToDoctorEmailRespondedArray: any = [];
  doctorToDoctorEmailNotRespondedArray: any = [];
  doctorToDoctorEmailReadArray: any = [];
  secondOpinionLogExpertRespondedArray: any = [];
  secondOpinionLogExpertNotRespondedArray: any = [];
  secondOpinionLogExpertReadArray: any = [];
  secondOpinionLogExpertNotRespondedFlag = false;
  secondOpinionLogExpertRespondedFlag = false;
  secondOpinionLogExpertReadFlag = false;
  OpinionLogRequesteNotRespondedFlag = false;
  OpinionLogRequesteRespondedFlag = false;
  OpinionLogRequesteReadFlag = false;
  expertPhysicianOpinionRespondedFlag = false;
  expertPhysicianOpinionNotRespondedFlag = false;
  expertPhysicianOpinionReadFlag = false;
  doctorToDoctorEmailRespondedFlag = false;
  doctorToDoctorEmailNotRespondedFlag = false;
  doctorToDoctorEmailReadFlag = false;
  secondOpinionLogExpertAllFlag = false;
  OpinionLogRequesteAllFlag = false;
  expertPhysicianOpinionAllFlag = false;
  doctorToDoctorEmailAllFlag = false;
  secondOpinionLogExpertAllArray: any = [];
  OpinionLogRequesteAllArray: any = [];
  expertPhysicianOpinionAllArray: any = [];
  doctorToDoctorEmailAllArray: any = [];

  showTableData = 0;
  appointmentList: any = [];
  visitedAppointmentList: any = [];
  pendingAppointmentList: any = [];
  visitedAppointmentFlag = false;
  pendingAppointmentFlag = false;
  is_visited = 1;
  showLoader = false;
  delegateId = localStorage.getItem('delegateId');
  aknowledgmentEmail = '';
  aknowledgmentLogId = '';
  maxChars = 300;
  emailContent: any = '';
  emailSubject: any = '';
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtOptions2: DataTables.Settings = {};
  dtOptions3: DataTables.Settings = {};

  constructor(
    public commonHelper: CommonHelper,
    private appConfig: AppConfig,
    private activatedRoute: ActivatedRoute,
    public sweetAlertHelper: SweetAlertHelper,
    private router: Router,
    private http: HttpClient,
    public userService: UserService
  ) {
    localStorage.setItem('childRoute', activatedRoute.snapshot.url[0].path);
    this.childRoute = activatedRoute.snapshot.url[0].path;
    // this.showTableData = 1;
  }

  ngOnInit() {
    this.loggedInUserId =
      this.activatedRoute.snapshot.paramMap.get('loggedInUserId');
    this.showDataVal = this.activatedRoute.snapshot.paramMap.get('showDataVal');
    if (this.delegateId) {
      this.findTransferLog(this.delegateId, this.showDataVal);
    } else {
      this.findTransferLog(this.loggedInUserId, this.showDataVal);
    }
    this.secondOpinionLogExpertReadFlag = true;
    this.OpinionLogRequesteReadFlag = true;
    this.expertPhysicianOpinionReadFlag = true;
    this.doctorToDoctorEmailReadFlag = true;
    this.pendingAppointmentFlag = true;
    this.dtOptions = { order: []};
    this.dtOptions1 = { order: [[7, "desc"]]};
    this.dtOptions2 = { order: [[8, "desc"]]};
    this.dtOptions3 = { order: [[5, "asc"]]};
  }

  async goToDemographics() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Confirm your desire to return to the 'Patient user' list. "
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminUploaddocComp.gotoDemgraphics
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/demographics/' + this.loggedInUserId,
      ]);
    // }
  }

  async onReset() {
    // if (
    //   confirm(
    //     "PLEASE CONFIRM: Are you certain you want to return to physician Home Page?"
    //   )
    // )
    // const alertStatus = await this.sweetAlertHelper.confirmPopUp(
    //   this.message.confirmTitle,
    //   this.message.confirmMessages.adminChangePassComp.onReset
    // );
    // if (alertStatus) {
      this.router.navigate([
        '/' + this.childRoute + '/dashboard/' + this.loggedInUserId,
      ]);
    // }
  }

  showData(selectedVal) {
    this.showSecondOpinionLogExpert(this.showTableData);
    this.showOpinionLogRequest(this.showTableData);
    this.showExpertPhysicianOpinionLogRequest(this.showTableData);
    this.showD2DEmailRequest(this.showTableData);
    this.showDataVal = selectedVal;
    if (selectedVal != 5) {
      this.findTransferLog(this.loggedInUserId, this.showDataVal);
    }
    if (selectedVal == 5) {
      this.findTransferredLog(this.loggedInUserId);
    }
  }

  findTransferredLog(userId) {
    if (this.delegateId) {
      userId = this.delegateId;
    }
    const finalData = {
      userId,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findTransferLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.logs.length > 0) {
            this.logFive = res.commandResult.data.logs;
          }

          // console.log(this.logFive);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  findTransferLog(userId, logType) {
    if (this.delegateId) {
      userId = this.delegateId;
    }
    const finalData = {
      userId,
      logType,
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_findOpinionLogApi,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.logs.length > 0) {
            if (logType == 5) {
              this.logFive = res.commandResult.data.logs;
            } else if (logType == 4) {
              this.logFour = res.commandResult.data.logs;
              this.doctorToDoctorEmailAllArray = res.commandResult.data.logs;
              const separatedArrays = res.commandResult.data.logs.reduce(
                (acc, item) => {
                  if (item.HasResponse === 1) {
                    acc.responded.push(item);
                  } else if (item.HasResponse === 0 && item.HasRead == 0) {
                    acc.notResponded.push(item);
                  } else if (item.HasRead == 1) {
                    acc.readArray.push(item);
                  } else {
                    acc.allArray.push(item);
                  }
                  return acc;
                },
                {responded: [], notResponded: [], readArray: [], allArray: []}
              );
              this.doctorToDoctorEmailRespondedArray =
                separatedArrays.responded;
              this.doctorToDoctorEmailNotRespondedArray =
                separatedArrays.notResponded;
              this.doctorToDoctorEmailReadArray = separatedArrays.readArray;
              // this.doctorToDoctorEmailAllArray = separatedArrays.allArray;
              console.log("Akshay", this.doctorToDoctorEmailAllArray)
            } else if (logType == 3) {
              this.logThree = res.commandResult.data.logs;
              this.expertPhysicianOpinionAllArray = res.commandResult.data.logs;
              const separatedArrays = res.commandResult.data.logs.reduce(
                (acc, item) => {
                  if (item.HasResponse === 1) {
                    acc.responded.push(item);
                  } else if (item.HasResponse === 0 && item.HasRead == 0) {
                    acc.notResponded.push(item);
                  } else if (item.HasRead == 1) {
                    acc.readArray.push(item);
                  } else {
                    acc.allArray.push(item);
                  }
                  return acc;
                },
                {responded: [], notResponded: [], readArray: [], allArray: []}
              );
              this.expertPhysicianOpinionRespondedArray =
                separatedArrays.responded;
              this.expertPhysicianOpinionNotRespondedArray =
                separatedArrays.notResponded;
              this.expertPhysicianOpinionReadArray = separatedArrays.readArray;
              // this.expertPhysicianOpinionAllArray = separatedArrays.allArray;
              console.log("Akshay 2", this.expertPhysicianOpinionAllArray)
            } else if (logType == 2) {
              this.logTwo = res.commandResult.data.logs;
              this.OpinionLogRequesteAllArray = res.commandResult.data.logs;
              const separatedArrays = res.commandResult.data.logs.reduce(
                (acc, item) => {
                  if (item.HasResponse === 1) {
                    acc.responded.push(item);
                  } else if (item.HasResponse === 0 && item.HasRead == 0) {
                    acc.notResponded.push(item);
                  } else if (item.HasRead == 1) {
                    acc.readArray.push(item);
                  } else {
                    acc.allArray.push(item);
                  }
                  return acc;
                },
                {responded: [], notResponded: [], readArray: [], allArray: []}
              );
              this.OpinionLogRequesteRespondedArray = separatedArrays.responded;
              this.OpinionLogRequesteNotRespondedArray =
                separatedArrays.notResponded;
              this.OpinionLogRequesteReadArray = separatedArrays.readArray;
              // this.OpinionLogRequesteAllArray = separatedArrays.allArray;
              console.log("Akshay 3", this.OpinionLogRequesteAllArray)
            } else {
              this.logOne = res.commandResult.data.logs;
              this.secondOpinionLogExpertAllArray = res.commandResult.data.logs;
              const separatedArrays = res.commandResult.data.logs.reduce(
                (acc, item) => {
                  if (item.HasResponse === 1) {
                    acc.responded.push(item);
                  } else if (item.HasResponse === 0 && item.HasRead == 0) {
                    acc.notResponded.push(item);
                  } else if (item.HasRead == 1 && item.HasResponse == 0) {
                    // console.log("at the top " + item);
                    acc.readArray.push(item);
                  } else {
                    acc.allArray.push(item);
                  }
                  return acc;
                },
                {responded: [], notResponded: [], readArray: [], allArray: []}
              );
              this.secondOpinionLogExpertRespondedArray =
                separatedArrays.responded;
              this.secondOpinionLogExpertNotRespondedArray =
                separatedArrays.notResponded;
              this.secondOpinionLogExpertReadArray = separatedArrays.readArray;
              // this.secondOpinionLogExpertAllArray = separatedArrays.allArray;
              console.log("Akshay 4", this.secondOpinionLogExpertAllArray)
            }
          }

          // console.log(this.logOne);
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  viewDouments(logDetails, showDataVal) {
    // console.log(logDetails);
    // tslint:disable-next-line: max-line-length
    this.router.navigate([
      '/' +
        this.childRoute +
        '/user-docs/' +
        logDetails.UniqueKey +
        '/' +
        logDetails.RecordId +
        '/' +
        this.loggedInUserId +
        '/' +
        logDetails.UserOptionId +
        '/' +
        showDataVal,
    ]);
  }

  sendAcknowledgement(logDetails, showDataVal, behaviourId) {
    // console.log(logDetails);
    // tslint:disable-next-line: max-line-length
    this.router.navigate([
      '/' +
        this.childRoute +
        '/view-docs-acknowledgement/' +
        logDetails.UniqueKey +
        '/' +
        logDetails.RecordId +
        '/' +
        this.loggedInUserId +
        '/' +
        logDetails.UserOptionId +
        '/' +
        behaviourId +
        '/' +
        showDataVal,
    ]);
  }

  reviewLog(logDetails, showDataVal, behaviourId) {
    // console.log(logDetails);
    // tslint:disable-next-line: max-line-length

    if(showDataVal == 1) {
      const reqData = {
      recordId: logDetails.RecordId,
      hasReadFlag: 1
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_sendD2dResponseApi,
        reqData,
        this.httpOptions
      )
      .subscribe(
        async (res: any) => {
          this.showLoader = false;
          // console.log("it is working")
        },
        (err) => {
          // console.log(err);
        }
      );
    }

    this.router.navigate([
      "/" +
        this.childRoute +
        "/reply-d2d/" +
        logDetails.UniqueKey +
        "/" +
        logDetails.RecordId +
        "/" +
        this.loggedInUserId +
        "/" +
        logDetails.UserOptionId +
        "/" +
        behaviourId +
        "/" +
        showDataVal,
    ]);
  }

  async fnTransferLog(logDetail) {
    let cnfMsg = "";
    let altMsg = "";

    if (logDetail.IsTransferred == 0) {
      // cnfMsg = 'Are you sure, you want to transfer this patient?';
      cnfMsg = this.message.confirmMessages.adminTransferLogComp.fnTransferLog;
    } else {
      cnfMsg = this.message.confirmMessages.adminTransferLogComp.fnTransferLog1;
      // cnfMsg = 'Are you sure, you want to revoke transfer of this patient?';
    }

    const confirmStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      cnfMsg
    );

    if (logDetail.IsTransferred == 0) {
      altMsg = this.message.alertMessages.adminTransferLogComp.fnTransferLog;
    } else {
      altMsg = this.message.alertMessages.adminTransferLogComp.fnTransferLog1;
    }

    if (confirmStatus) {
      const finalData = {
        logId: logDetail.RecordId,
        loggedInUserId: this.loggedInUserId,
        isTransferred: logDetail.IsTransferred,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_transferOpinionLogApi,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            this.showLoader = false;
            // console.log(res);
            this.sweetAlertHelper.alertPopUp(altMsg);
            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    }
  }

  closePatientModal() {
    this.showPatientModal = false;
  }

  closeRequestingModal() {
    this.showRequestingModal = false;
  }

  openPatientModal(logId) {
    this.selectedPatient = this.logFive.find((e) => e.RecordId == logId);
    // console.log(this.selectedPatient);
    this.showPatientModal = true;
  }

  openRequestingModal(logId) {
    this.selectedRequesting = this.logFive.find((e) => e.RecordId == logId);
    this.showRequestingModal = true;
  }

  showSecondOpinionLogExpert(value) {
    if (value == 0) {
      this.showTableData = value;
      this.secondOpinionLogExpertAllFlag = true;
      this.secondOpinionLogExpertReadFlag = false;
      this.secondOpinionLogExpertNotRespondedFlag = false;
      this.secondOpinionLogExpertRespondedFlag = false;
    } else if (value == 1) {
      this.showTableData = value;
      this.secondOpinionLogExpertAllFlag = false;
      this.secondOpinionLogExpertReadFlag = true;
      this.secondOpinionLogExpertNotRespondedFlag = false;
      this.secondOpinionLogExpertRespondedFlag = false;
    } else if (value == 2) {
      this.showTableData = value;
      this.secondOpinionLogExpertAllFlag = false;
      this.secondOpinionLogExpertReadFlag = false;
      this.secondOpinionLogExpertNotRespondedFlag = true;
      this.secondOpinionLogExpertRespondedFlag = false;
    } else if (value == 3) {
      this.showTableData = value;
      this.secondOpinionLogExpertAllFlag = false;
      this.secondOpinionLogExpertReadFlag = false;
      this.secondOpinionLogExpertNotRespondedFlag = false;
      this.secondOpinionLogExpertRespondedFlag = true;
    }
  }

  showOpinionLogRequest(value) {
    if (value == 0) {
      this.showTableData = value;
      this.OpinionLogRequesteAllFlag = true;
      this.OpinionLogRequesteNotRespondedFlag = false;
      this.OpinionLogRequesteRespondedFlag = false;
      this.OpinionLogRequesteReadFlag = false;
    } else if (value == 1) {
      this.showTableData = value;
      this.OpinionLogRequesteAllFlag = false;
      this.OpinionLogRequesteNotRespondedFlag = false;
      this.OpinionLogRequesteRespondedFlag = false;
      this.OpinionLogRequesteReadFlag = true;
    } else if (value == 2) {
      this.showTableData = value;
      this.OpinionLogRequesteAllFlag = false;
      this.OpinionLogRequesteNotRespondedFlag = true;
      this.OpinionLogRequesteRespondedFlag = false;
      this.OpinionLogRequesteReadFlag = false;
    } else if (value == 3) {
      this.showTableData = value;
      this.OpinionLogRequesteAllFlag = false;
      this.OpinionLogRequesteNotRespondedFlag = false;
      this.OpinionLogRequesteRespondedFlag = true;
      this.OpinionLogRequesteReadFlag = false;
    }
  }

  showExpertPhysicianOpinionLogRequest(value) {
    if (value == 0){
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = true;
      this.expertPhysicianOpinionRespondedFlag = false;
      this.expertPhysicianOpinionNotRespondedFlag = false;
      this.expertPhysicianOpinionReadFlag = false;
    } else if (value == 1) {
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = false;
      this.expertPhysicianOpinionRespondedFlag = false;
      this.expertPhysicianOpinionNotRespondedFlag = false;
      this.expertPhysicianOpinionReadFlag = true;
    } else if (value == 2) {
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = false;
      this.expertPhysicianOpinionRespondedFlag = false;
      this.expertPhysicianOpinionNotRespondedFlag = true;
      this.expertPhysicianOpinionReadFlag = false;
    } else if (value == 3) {
      this.showTableData = value;
      this.expertPhysicianOpinionAllFlag = false;
      this.expertPhysicianOpinionRespondedFlag = true;
      this.expertPhysicianOpinionNotRespondedFlag = false;
      this.expertPhysicianOpinionReadFlag = false;
    }
  }

  showD2DEmailRequest(value) {
    if (value == 0) {
      this.showTableData = value;
      this.doctorToDoctorEmailAllFlag = true;
      this.doctorToDoctorEmailRespondedFlag = false;
      this.doctorToDoctorEmailNotRespondedFlag = false;
      this.doctorToDoctorEmailReadFlag = false;
    } else if (value == 1) {
      this.showTableData = value;
      this.doctorToDoctorEmailAllFlag = false;
      this.doctorToDoctorEmailRespondedFlag = false;
      this.doctorToDoctorEmailNotRespondedFlag = false;
      this.doctorToDoctorEmailReadFlag = true;
    } else if (value == 2) {
      this.showTableData = value;
      this.doctorToDoctorEmailAllFlag = false;
      this.doctorToDoctorEmailRespondedFlag = false;
      this.doctorToDoctorEmailNotRespondedFlag = true;
      this.doctorToDoctorEmailReadFlag = false;
    } else if (value == 3) {
      this.showTableData = value;
      this.doctorToDoctorEmailAllFlag = false;
      this.doctorToDoctorEmailRespondedFlag = true;
      this.doctorToDoctorEmailNotRespondedFlag = false;
      this.doctorToDoctorEmailReadFlag = false;
    }
  }

  showAppointmentList(is_visited) {
    if (is_visited == 0) {
      this.showTableData = is_visited;
      this.visitedAppointmentFlag = false;
      this.pendingAppointmentFlag = true;
    } else if (is_visited == 1) {
      this.showTableData = is_visited;
      this.visitedAppointmentFlag = true;
      this.pendingAppointmentFlag = false;
    }
    this.showDataVal = '6';
    const finalData = {
      user_id: this.loggedInUserId,
      company_slug: this.companySlug,
      is_visited
    };
    this.showLoader = true;
    this.http
      .post(
        this.appConfig.getPhpUrl() + ApiHelper.php_getAppointmentList,
        finalData,
        this.httpOptions
      )
      .subscribe(
        (res: any) => {
          this.showLoader = false;
          if (res.commandResult.data.appointments.length > 0) {
            this.appointmentList = res.commandResult.data.appointments;
          }
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  async markAsVisited(physician_id, appointment_id) {
    const alertStatus = await this.sweetAlertHelper.confirmPopUp(
      this.message.confirmTitle,
      this.message.confirmMessages.markPatientVisited.markPatientVisited
    );
    if (alertStatus) {
      const finalData = {
        user_id: physician_id,
        appointment_id
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_visitAppointment,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            this.showLoader = false;
            window.location.reload();
          },
          (err) => {
            // console.log(err);
          }
        );
    } else {
      return false;
    }
  }

  openEmailPopup(logId, requestingEmail) {
    console.log(logId);
    console.log(requestingEmail);

    this.aknowledgmentEmail = requestingEmail;
    this.aknowledgmentLogId = logId;
    this.showEmailModal = true;
  }

  closeEmailModal() {
    this.showEmailModal = false;
    this.emailSubject = "";
    this.emailContent = "";
  }

  async sendEmail() {
    console.log(this.aknowledgmentEmail);
    console.log(this.aknowledgmentLogId);
    console.log(this.emailSubject);
    console.log(this.emailContent);
    if (!this.emailSubject) {
      await this.sweetAlertHelper.alertPopUp('Subject is missing.');
    } else if (!this.emailContent) {
      await this.sweetAlertHelper.alertPopUp('Content is missing.');
    } else {
      const alertStatus = await this.sweetAlertHelper.confirmPopUp(
        this.message.confirmTitle,
        "Are you sure, you want to send email to requesting physician?"
      );
      const finalData = {
        email: this.aknowledgmentEmail,
        logId: this.aknowledgmentLogId,
        emailSubject: this.emailSubject,
        emailContent: this.emailContent,
        loggedInUserId: this.loggedInUserId,
      };
      this.showLoader = true;
      this.http
        .post(
          this.appConfig.getPhpUrl() + ApiHelper.php_d2dEmail,
          finalData,
          this.httpOptions
        )
        .subscribe(
          (res: any) => {
            this.showEmailModal = false;
            this.showLoader = false;
            //clear fields after mail.
            this.emailSubject = "";
            this.emailContent = "";
          },
          (err) => {
            this.showLoader = false;

            console.log(err);
          }
        );
    }
  }
}
